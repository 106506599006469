import Resource from './base';

const baseUrl = '/api/auth';

const actions = (client) => ({
  async login(credentials) {
    const { access, refresh } = await client.post(
      `${baseUrl}/token/`,
      credentials,
    );
    if (access && refresh) {
      client.$setToken(access, refresh);
      return access;
    }
    return null;
  },

  validateToken(token) {
    return client.post(`${baseUrl}/auth/password_reset/validate_token/`, token);
  },

  changeProfilePassword(data) {
    return client.post(`${baseUrl}/change-password/`, data);
  },

  me() {
    return client.get(`/api/users/me/`);
  },

  createUser(data) {
    return client.post(`${baseUrl}/register-by-admin/`, data);
  },
});

export default Resource(baseUrl, actions);
