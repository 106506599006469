import Resource from './base';

const baseUrl = '/api';

const actions = (client) => ({
  getUsers(params) {
    return client.get(`${baseUrl}/users`, { params });
  },
  getUser(id) {
    return client.get(`${baseUrl}/users/${id}`);
  },
  createUser(params) {
    return client.post(`${baseUrl}/users/`, params);
  },
  updateUser(id, data) {
    return client.put(`${baseUrl}/users/${id}/`, data);
  },
  patchUser(id, data) {
    return client.patch(`${baseUrl}/users/${id}/`, data);
  },
  deleteUser(id) {
    return client.delete(`${baseUrl}/users/${id}/`);
  },
  changePassword(params) {
    return client.post(`${baseUrl}/users/set_password/`, params);
  },
  verifyUser(params) {
    return client.post(`${baseUrl}/users/verify_user_email/`, params);
  },
  exportUsers() {
    return client.post(`${baseUrl}/users/export_users/`);
  },
});

export default Resource(baseUrl, actions);
