import axios from 'axios';

const Storage = window.localStorage;

const rootConfig = {
  http: {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000,
  },
};

const createClient = (configs) => {
  const {
    prefixToken = 'Bearer',
    tokenName = 'access',
    refreshTokenName = 'refresh',
    storage = Storage,
    requestInterceptors = () => [],
    responseInterceptors = () => [],
    ...axiosConfigs
  } = configs;
  const http = axios.create(axiosConfigs);
  const setToken = (token) => {
    http.defaults.headers.common.Authorization = `${prefixToken} ${token}`;
  };

  http.$getToken = () => {
    return storage.getItem(tokenName);
  };
  http.$getRefreshToken = () => {
    return storage.getItem(refreshTokenName);
  };

  http.$setToken = (token, refresh) => {
    setToken(token);
    storage.setItem(tokenName, token);
    if (refresh) {
      storage.setItem(refreshTokenName, refresh);
    }
  };

  http.$clearToken = () => {
    delete http.defaults.headers.common.Authorization;
    storage.removeItem(tokenName);
    storage.removeItem(refreshTokenName);
  };

  http.$refreshToken = (e) => {
    const originalRequest = e.config;
    if (
      e.response &&
      e.response.status &&
      e.response.status === 401 &&
      originalRequest.retryRefresh
    ) {
      http.$clearToken();
      return Promise.reject(e);
    }

    if (
      e.response &&
      e.response.status &&
      e.response.status === 401 &&
      !originalRequest.retryRefresh
    ) {
      originalRequest.retryRefresh = true;
      const refresh = http.$getRefreshToken();
      if (refresh) {
        return axios
          .post(`${rootConfig.http.baseURL}/api/auth/refresh/`, { refresh })
          .then(
            (res) => {
              originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
              http.$setToken(res.data.access, null);
              return http(originalRequest);
            },
            (error) => {
              http.$clearToken();
              if (error && error.response && error.response.status === 401) {
                if (window.location.href.indexOf('/account/login') === -1) {
                  window.location.replace('/account/login');
                }
                return Promise.reject(error);
              }
              return Promise.reject(error);
            },
          );
      }
      if (window.location.href.indexOf('/login') === -1) {
        window.location.replace('/account/login');
      }
    }
    return Promise.reject(e);
  };

  requestInterceptors(http).forEach((interceptor) =>
    http.interceptors.request.use(...interceptor),
  );
  responseInterceptors(http).forEach((interceptor) =>
    http.interceptors.response.use(...interceptor),
  );

  if (http.$getToken()) {
    setToken(http.$getToken());
  }

  return http;
};

const client = createClient({
  ...rootConfig.http,
  responseInterceptors(http) {
    return [
      [
        (response) => {
          const { data } = response;
          return data;
        },
        (error) => {
          if (error && error.response && error.response.status === 401) {
            return http.$refreshToken(error);
          }
          return Promise.reject(error);
        },
      ],
    ];
  },
});

export default client;
