import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import logo from '../../../assets/images/logo-mgfa.png';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90vh',
    px: 4,
    width: '100%',
  }),
);

const LoggedOutCard = styled(Card)(
  css({
    borderRadius: 'lg',
    maxWidth: '340px',
    minWidth: '300px',
    minHeight: '275px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  }),
);

const Logo = styled.img(
  css({
    display: 'block',
    mb: 8,
    mx: 'auto',
    width: 'auto',
  }),
);

const LoggedOutLayout = ({ children }) => (
  <Main p={{ _: '3', lg: '10' }}>
    <LoggedOutCard>
      <Card.Body py={8} textAlign="center">
        <Logo src={logo} alt="MGFA" />
        {children}
      </Card.Body>
    </LoggedOutCard>
  </Main>
);

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
