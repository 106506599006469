import styled from '@emotion/styled';
import {
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  compose,
} from 'styled-system';
import styledForwardProp from '@styled-system/should-forward-prop';

export const classesStyles = (prop) => (props) => {
  const { classes = {} } = props;
  const styles = classes[prop];

  return typeof styles === 'function' ? styles(props) : styles;
};

export const system = () =>
  compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
  );

const styledBox =
  (element = 'div', config = {}) =>
  (...args) => {
    const { shouldForwardProp = () => true } = config;

    return styled(element, {
      shouldForwardProp: (prop) =>
        styledForwardProp(prop) && shouldForwardProp(prop),
    })(...args, system());
  };

export default styledBox;
