import React, { forwardRef } from 'react';
import { bool, number, func, oneOfType, array, arrayOf } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import {
  MdKeyboardArrowLeft as ArrowLeft,
  MdKeyboardArrowRight as ArrowRight,
} from 'react-icons/md';
import BaseTypography from '@bit/medicalwebexperts.mwe-ui.typography';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import Form from '@bit/medicalwebexperts.mwe-ui.form';
import styledBox from '../../utils/system';

const propTypes = {
  canNextPage: bool.isRequired,
  canPreviousPage: bool.isRequired,
  dense: bool,
  nextPage: func.isRequired,
  pageCount: number.isRequired,
  pageIndex: number.isRequired,
  pageOptions: oneOfType([array]).isRequired,
  pageSize: number.isRequired,
  previousPage: func.isRequired,
  rowsPerPageOptions: arrayOf(number),
  setPageSize: func.isRequired,
};

const defaultProps = {
  dense: false,
  rowsPerPageOptions: [10, 20, 30, 40],
};

const TablePagination = styledBox('div')(
  { label: 'TablePagination' },
  ({ dense }) =>
    css({
      alignItems: 'center',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'flex-end',
      py: dense ? 1 : 4,
    }),
);

const Typography = styled(BaseTypography)(
  css({
    px: 4,
    whiteSpace: 'nowrap',
  }),
);

const Select = styled(Form.Control)(
  css({
    border: 'none',
    cursor: 'pointer',
    maxWidth: '56px',
    px: 1,
  }),
);

const Component = forwardRef(
  (
    {
      canPreviousPage,
      canNextPage,
      pageIndex,
      pageOptions,
      pageCount,
      setPageSize,
      nextPage,
      previousPage,
      pageSize,
      rowsPerPageOptions,
      ...props
    },
    ref,
  ) => {
    return (
      <TablePagination ref={ref} {...props}>
        <Typography variant="caption">Rows per page:</Typography>
        <Select
          as="select"
          size="sm"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {rowsPerPageOptions.map((rowsPerPageOpt) => (
            <option key={rowsPerPageOpt} value={rowsPerPageOpt}>
              {rowsPerPageOpt}
            </option>
          ))}
        </Select>
        <Typography variant="caption">
          {`${pageIndex + 1} of ${pageOptions.length}`}
        </Typography>
        <IconButton
          size="sm"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          <ArrowLeft />
        </IconButton>
        <IconButton size="sm" onClick={nextPage} disabled={!canNextPage}>
          <ArrowRight />
        </IconButton>
      </TablePagination>
    );
  },
);

Component.displayName = 'TablePagination';
Component.propTypes = propTypes;
Component.defaultProps = defaultProps;

export default Component;
