import React, { useEffect, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Spinner from '@bit/medicalwebexperts.mwe-ui.spinner';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoggedOutLayout from '../Layout/LoggedOutLayout/LoggedOutLayout';
import LoggedInLayout from '../Layout/LoggedInLayout/LoggedInLayout';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import ActivityLog from '../../pages/ActivityLog';

const LoginPage = lazy(() => import('../../pages/Login'));
const NotFoundPage = lazy(() => import('../../pages/404'));
const ListsUsers = lazy(() => import('../../pages/Users/Lists'));
const UserManagement = lazy(() => import('../../pages/Users/UserManagement'));
const Profile = lazy(() => import('../../pages/Profile/Profile'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Loader = (props) => (
  <Suspense
    {...props}
    fallback={
      <Flex justifyContent="center" alignItems="center">
        <Spinner size="lg" />
      </Flex>
    }
  />
);

const App = () => (
  <Router>
    <Helmet titleTemplate="%s - Myasthenia Gravis Foundation of America" />
    <ScrollToTop />
    <ErrorBoundary>
      <Switch>
        <Route path="/account">
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route path="/account/login" exact>
                  <LoginPage />
                </Route>
                <Redirect to="/account/login" />
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </Route>

        <PrivateRoute path="/">
          <LoggedInLayout>
            <Loader>
              <Switch>
                <PrivateRoute path="/" exact>
                  <ListsUsers />
                </PrivateRoute>
                <PrivateRoute path="/users/create" exact>
                  <UserManagement />
                </PrivateRoute>
                <PrivateRoute path="/users/edit/:userId">
                  <UserManagement />
                </PrivateRoute>
                <PrivateRoute path="/profile/edit">
                  <Profile />
                </PrivateRoute>
                <PrivateRoute path="/activity" exact>
                  <ActivityLog />
                </PrivateRoute>
                <PrivateRoute path="*">
                  <NotFoundPage />
                </PrivateRoute>
              </Switch>
            </Loader>
          </LoggedInLayout>
        </PrivateRoute>
      </Switch>
    </ErrorBoundary>
  </Router>
);

export default App;
