import React from 'react';
import { string, bool } from 'prop-types';
import { useFormikContext } from 'formik';
import Button from '@bit/medicalwebexperts.mwe-ui.button';

const propTypes = {
  enabledReset: bool,
  enabledSubmit: bool,
  loadingText: string,
  resetText: string,
  submitText: string,
  variantColor: string,
};

const defaultProps = {
  enabledReset: false,
  enabledSubmit: false,
  loadingText: 'Saving...',
  resetText: 'Discard Changes',
  submitText: 'Save',
  variantColor: 'primary',
};

const Buttons = ({
  enabledReset,
  enabledSubmit,
  loadingText,
  resetText,
  submitText,
  variantColor,
}) => {
  const { dirty, isSubmitting, isValid, submitCount } = useFormikContext();
  const draft = submitCount === 0;

  return (
    <>
      <Button
        size="sm"
        mr={2}
        type="reset"
        variant="text"
        disabled={(!dirty || isSubmitting) && !enabledReset}
      >
        {resetText}
      </Button>
      <Button
        size="sm"
        type="submit"
        variantColor={variantColor}
        loading={isSubmitting}
        loadingText={loadingText}
        disabled={(!isValid || (!dirty && draft)) && !enabledSubmit}
      >
        {submitText}
      </Button>
    </>
  );
};

Buttons.propTypes = propTypes;
Buttons.defaultProps = defaultProps;

export default Buttons;
