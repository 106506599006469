/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { shape, func, node, string, bool } from 'prop-types';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Drawer, { useDrawerState } from '@bit/medicalwebexperts.mwe-ui.drawer';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import { useDown } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import Buttons from '../Form/Buttons';
import Formik from '../Form/Formik';

const propTypes = {
  filterTitle: string.isRequired,
  onSearch: func.isRequired,
  initialValues: shape({}).isRequired,
  children: node.isRequired,
  loading: bool,
};

const classes = {
  drawer: {
    root: {
      border: 'none',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      transform: 'translateX(100%)',
      transition: 'transform 250ms ease',
      '&[data-enter]': {
        transform: 'translateX(0)',
      },
      form: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        '> div:first-of-type': {
          overflowY: 'auto',
          flex: '1 1 0',
        },
        '> div:last-of-type': {
          display: 'flex',
          borderTop: '1px solid',
          borderTopColor: 'gray.300',
          justifyContent: 'flex-end',
        },
      },
    },
  },
  header: {
    root: {
      p: 5,
      borderBottom: '1px solid',
      borderBottomColor: 'gray.300',
    },
    close: {
      button: {
        boxShadow: 'btn',
      },
    },
  },
  filter: {
    endIcon: {
      alignItems: 'center',
      bg: 'whiteAlpha.400',
      borderRadius: 'full',
      display: 'flex',
      justifyContent: 'center',
      size: 5,
    },
  },
};

const DrawerFilter = ({ onSearch, initialValues, children, loading }) => {
  const [filterCount, setFilterCount] = useState(0);
  const drawer = useDrawerState({ animated: true });
  const fullWidth = useDown('md');

  const handleSearch = async (rawValues, formikBag) => {
    const values = Object.fromEntries(
      Object.entries(rawValues).filter(([, v]) => {
        if (Array.isArray(v)) {
          return v.length > 0;
        }
        return typeof v === 'object' ? v.value !== '' : v !== '';
      }),
    );
    const count = Object.keys(values).length;

    await onSearch(values, formikBag);
    setFilterCount(count);
    drawer.hide();
  };

  return (
    <>
      <Flex justifyContent="flex-end">
        <Drawer.Disclosure
          {...drawer}
          size="sm"
          as={Button}
          variantColor="primary"
          classes={classes.filter}
          startIcon={<Icon name="tune" size={5} />}
          endIcon={filterCount || undefined}
        >
          Filters
        </Drawer.Disclosure>
      </Flex>

      <Drawer
        state={drawer}
        placement="right"
        ariaLabel="Filters"
        classes={classes.drawer}
        preventBodyScroll={false}
        scale={fullWidth ? 'full' : 'sm'}
      >
        <Drawer.Header withClose classes={classes.header}>
          <Typography variant="h5">Filters</Typography>
        </Drawer.Header>
        <Formik
          onReset={handleSearch}
          onSubmit={handleSearch}
          initialValues={initialValues}
        >
          <Drawer.Body>{children}</Drawer.Body>
          <Drawer.Body>
            {!loading && (
              <Buttons submitText="Apply filters" resetText="Clear Filters" />
            )}
          </Drawer.Body>
        </Formik>
      </Drawer>
    </>
  );
};

DrawerFilter.propTypes = propTypes;

export default DrawerFilter;
