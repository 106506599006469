import Resource from './base';

const baseUrl = '/api';

const actions = (client) => ({
  getActivities(params) {
    return client.get(`${baseUrl}/activity`, { params });
  },
});

export default Resource(baseUrl, actions);
