import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';
import SnackbarProvider from '@bit/medicalwebexperts.mwe-ui.snackbar-context';

import theme from './themes/preset';
import App from './components/App/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import 'typeface-roboto';
import AuthProvider from './contexts/AuthContext';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Normalize />
      <AuthProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
