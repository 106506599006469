import React from 'react';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import { string, node, bool } from 'prop-types';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import StatusIndicator from '@bit/medicalwebexperts.mwe-ui.status-indicator';

const propTypes = {
  title: string.isRequired,
  notUnderlined: bool,
  subtitle: string,
  endElement: node,
  icon: string,
  status: string,
  statusColor: string,
};

const defaultProps = {
  notUnderlined: false,
  endElement: null,
  subtitle: null,
  icon: null,
  status: null,
  statusColor: null,
};

const Wrapper = styled(Flex)(
  css({
    alignItems: 'center',
    maxHeight: 16,
    py: 5,
    width: '100%',
  }),
  ({ dashed }) =>
    dashed &&
    css({
      borderBottom: '1px dashed',
      borderBottomColor: 'gray.200',
    }),
);

const IconHeader = ({
  icon,
  title,
  endElement,
  notUnderlined,
  status,
  statusColor,
  subtitle,
}) => (
  <Card.Header py={0}>
    <Wrapper dashed={!notUnderlined}>
      {icon && <Icon name={icon} color="gray.400" size={22} mr={2} />}
      <Flex.Box>
        <Flex alignItems="center" flexWrap="wrap">
          <Typography variant="subtitle2" flexGrow={1} mr={status && 2}>
            {title}
          </Typography>
          {status && (
            <StatusIndicator
              classes={{ bullet: { ml: 0, mr: 1 } }}
              status={statusColor}
              size="sm"
            >
              <Typography variant="body2" color="gray.600">
                {status}
              </Typography>
            </StatusIndicator>
          )}
        </Flex>
        {subtitle && (
          <Typography variant="body2" color="gray.600">
            {subtitle}
          </Typography>
        )}
      </Flex.Box>
      <Flex.Box flexGrow={1} />
      {endElement}
    </Wrapper>
  </Card.Header>
);

IconHeader.propTypes = propTypes;
IconHeader.defaultProps = defaultProps;

export default IconHeader;
