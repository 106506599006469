/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';

export default ({
  columns,
  fetchData,
  initialFilters = {},
  extraInstance = {},
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [totalRows, setTotalRows] = useState(0);
  const filtersCount = useMemo(() => Object.keys(filters).length, [filters]);
  const snackbar = useSnackbarDispatch();
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualPagination: true,
      pageCount,
      loading,
      ...extraInstance,
    },
    usePagination,
  );
  const {
    state: { pageIndex, pageSize },
  } = tableInstance;

  const load = async () => {
    setLoading(true);
    try {
      const { data: response, total } = await fetchData({
        pageSize,
        pageIndex,
        filters,
      });

      setData(response);
      setPageCount(Math.ceil(total / pageSize));
      setTotalRows(total);
    } catch (err) {
      setData([]);
      setPageCount(0);
      snackbar.open({
        message: 'Sorry, there was an error loading the data. Try again later',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (pageIndex !== 0) {
      tableInstance.gotoPage(0);
    } else {
      load();
    }
  }, [filters]);

  return {
    totalRows,
    data,
    setData,
    loading,
    setLoading,
    pageCount,
    setPageCount,
    filters,
    filtersCount,
    setFilters,
    instance: tableInstance,
  };
};
