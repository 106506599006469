import React from 'react';
import { string, shape, bool, number } from 'prop-types';
import Spinner from '@bit/medicalwebexperts.mwe-ui.spinner';
import BaseTable from '@bit/medicalwebexperts.mwe-ui.table';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { useTheme } from '@emotion/react';
import TablePagination from './TablePagination';
import Card from '../Card/Card';

const propTypes = {
  instance: shape({}).isRequired,
  noResultMessage: string,
  headerBg: string,
  dense: bool,
  selectedIndex: number,
  showPagination: bool,
};

const defaultProps = {
  noResultMessage: 'No results available',
  headerBg: 'white',
  dense: false,
  selectedIndex: undefined,
  showPagination: true,
};

const HeadCell = styled(BaseTable.Cell)(
  css({
    fontSize: 'xs',
    fontWeight: 'medium',
    textTransform: 'uppercase',
  }),
);

const Pagination = styled(TablePagination)(
  css({
    span: {
      fontSize: 'xs',
    },
  }),
);

const Table = ({
  instance: { state, ...restInstance },
  noResultMessage,
  headerBg,
  dense,
  selectedIndex,
  showPagination,
  ...props
}) => {
  const theme = useTheme();

  const renderContent = () => {
    if (restInstance.loading) {
      return (
        <BaseTable.Row>
          <BaseTable.Cell
            textAlign="center"
            colSpan={restInstance.columns.length}
            height="20vh"
          >
            <Spinner size="md" />
          </BaseTable.Cell>
        </BaseTable.Row>
      );
    }

    if (restInstance.rows.length > 0) {
      return restInstance.rows.map((row, index) => {
        restInstance.prepareRow(row);

        return (
          <BaseTable.Row {...row.getRowProps()}>
            {row.cells.map((cell, cellIndex) => (
              <BaseTable.Cell
                borderLeft={
                  cellIndex === 0 &&
                  index === selectedIndex &&
                  `10px solid ${theme.colors.primary.main}`
                }
                bg={index === selectedIndex && 'primary.moreLight'}
                {...cell.getCellProps([
                  { p: !dense ? 5 : 3, fontWeight: 'normal' },
                  cell.column.css || {},
                ])}
                fontWeight={index === selectedIndex && 'bold'}
              >
                {cell.render('Cell')}
              </BaseTable.Cell>
            ))}
          </BaseTable.Row>
        );
      });
    }

    return (
      <BaseTable.Row>
        <BaseTable.Cell colSpan={restInstance.columns.length}>
          {noResultMessage}
        </BaseTable.Cell>
      </BaseTable.Row>
    );
  };

  return (
    <Card boxShadow="none">
      <BaseTable color="gray.700" {...restInstance.getTableProps()} {...props}>
        <BaseTable.Head bg={headerBg} {...restInstance.getHeaderProps}>
          {restInstance.headerGroups.map((headerGroup) => (
            <BaseTable.Row color="text" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <HeadCell
                  {...column.getHeaderProps([
                    { p: !dense ? 5 : 3 },
                    column.css || {},
                  ])}
                >
                  {column.render('Header')}
                </HeadCell>
              ))}
            </BaseTable.Row>
          ))}
        </BaseTable.Head>

        <BaseTable.Body {...restInstance.getTableBodyProps()}>
          {renderContent()}
        </BaseTable.Body>
      </BaseTable>
      {showPagination && (
        <Pagination
          canPreviousPage={restInstance.canPreviousPage}
          canNextPage={restInstance.canNextPage}
          pageOptions={restInstance.pageOptions}
          pageCount={restInstance.pageCount}
          setPageSize={restInstance.setPageSize}
          nextPage={restInstance.nextPage}
          previousPage={restInstance.previousPage}
          pageIndex={state.pageIndex}
          pageSize={state.pageSize}
        />
      )}
    </Card>
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
