import React from 'react';
import { string, node, bool } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { MdArrowBack } from 'react-icons/md';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { Helmet } from 'react-helmet';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';

const propTypes = {
  browserTitle: string,
  children: node.isRequired,
  hasBack: bool,
  rightPanel: node,
  title: string,
};

const defaultProps = {
  browserTitle: undefined,
  hasBack: false,
  rightPanel: null,
  title: undefined,
};

const TitleBar = styled(Flex)(
  css({
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 8,
  }),
);

const Page = ({ browserTitle, hasBack, children, rightPanel, title }) => {
  const { goBack } = useHistory();

  return (
    <>
      <Helmet title={browserTitle || title} />
      {(title || rightPanel) && (
        <TitleBar>
          {title && (
            <Flex alignItems="center">
              {hasBack && (
                <IconButton size="sm" bg="transparent" onClick={goBack} mr={2}>
                  <Icon as={MdArrowBack} size={5} />
                </IconButton>
              )}
              <Typography variant="h5">{title}</Typography>
            </Flex>
          )}
          {rightPanel && <Flex>{rightPanel}</Flex>}
        </TitleBar>
      )}
      {children}
    </>
  );
};

Page.propTypes = propTypes;
Page.defaultProps = defaultProps;

export default Page;
