import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';
import icons from './icons';

export default {
  ...preset,
  fonts: {
    ...preset.fonts,
    body: 'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  colors: {
    ...preset.colors,
    background: '#f9f9f9',
    primary: {
      light: '#ffce86',
      main: '#008d97',
      dark: '#c68422',
    },
    secondary: {
      light: '#dbedfc',
      main: '#b7d9f4',
      dark: '#5889b1',
    },
  },
  sizes: {
    ...preset.sizes,
    sidebar: '240px',
  },
  icons,
};
