import {
  MdAccountBox,
  MdAdd,
  MdDateRange,
  MdDelete,
  MdEdit,
  MdEmail,
  MdHighlightOff,
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdLock,
  MdOutlineCloudDownload,
  MdOutlineDisabledByDefault,
  MdPerson,
  MdPhone,
  MdSettings,
  MdStars,
  MdTune,
} from 'react-icons/md';

const icons = {
  lock: MdLock,
  email: MdEmail,
  person: MdPerson,
  tune: MdTune,
  arrowDown: MdKeyboardArrowDown,
  arrowUp: MdKeyboardArrowUp,
  arrowLeft: MdKeyboardArrowLeft,
  arrowRight: MdKeyboardArrowRight,
  close: MdHighlightOff,
  date: MdDateRange,
  account: MdAccountBox,
  phone: MdPhone,
  settings: MdSettings,
  delete: MdDelete,
  disable: MdOutlineDisabledByDefault,
  add: MdAdd,
  edit: MdEdit,
  MdKeyboardArrowDown,
  Admin: MdStars,
  download: MdOutlineCloudDownload,
};

export default icons;
