/* eslint-disable react/destructuring-assignment */
import React, { useMemo } from 'react';
import css from '@styled-system/css';
import styledBox from '@emotion/styled';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import { useUp } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import moment from 'moment';
import Badge from '@bit/medicalwebexperts.mwe-ui.badge';
import Table from '../components/Table/Table';
import useTable from '../hooks/useTable';
import Page from '../components/Page/Page';
import ActivityService from '../services/resources/activity';
import Field from '../components/Form/Field';
import DrawerFilter from '../components/Filters/DrawerFilter';
import Filter from '../components/Filters/Filter';
import Card from '../components/Card/Card';

const VERB_LABELS = {
  VERIFY_USER: 'User Verified / Unverified',
  CREATED_USER: 'User Created',
  CHANGE_PASSWORD: 'Password Changed',
  UPDATED_USER: 'User Updated',
  DELETED_USER: 'User Deleted',
};

const LOG_NAME_OPTIONS = [
  { value: 'VERIFY_USER', label: 'User verified' },
  { value: 'CREATED_USER', label: 'User created' },
  { value: 'CHANGE_PASSWORD', label: 'Password changed' },
  { value: 'UPDATED_USER', label: 'User updated' },
  { value: 'DELETED_USER', label: 'User Deleted' },
];

const LOG_COLORS = {
  VERIFY_USER: { bg: '#FDEDDC', text: '#E07D54' },
  CREATED_USER: { bg: '#E1EEFE', text: '#709CE4' },
  CHANGE_PASSWORD: { bg: '#FBE6D2', text: '#C19C43' },
  UPDATED_USER: { bg: '#F0ECFE', text: '#8D6BDC' },
  DELETED_USER: { bg: '#FCF2F2', text: '#F04337' },
};

const ResponsiveBox = styledBox(Box)(
  { label: 'ResponsiveBox' },
  css({
    flex: '1',
    p: '1',
    boxSizing: 'border-box',
    m: 0,
    width: ['100%', '100%', '100%', '100%', 'auto'],
    textAlign: 'center',
  }),
);

const fetchData = async ({ pageIndex, pageSize, filters: query }) => {
  const filters = {
    page: pageIndex + 1,
    limit: pageSize,
    actor: query.actor,
    target: query.target,
    verb: query.verb?.value,
  };

  const results = await ActivityService.getActivities(filters);
  return { data: results.results, total: results.count };
};

const initialFilters = {
  actor: '',
  target: '',
  verb: '',
};

const ActivityLog = () => {
  const isDesktop = useUp('lg');

  const columns = useMemo(
    () => [
      {
        Header: 'IP Address',
        accessor: 'ipAddress',
      },
      {
        Header: 'Date',
        accessor: 'dateCreated',
        css: {},
        Cell: (args) => {
          const { value } = args;
          return moment.utc(value).format('MM/DD/yyyy');
        },
      },
      {
        Header: 'Causer',
        accessor: 'actor',
      },
      {
        Header: 'Action',
        accessor: 'verb',
        Cell: (args) => {
          return (
            <Badge
              pill
              bg={LOG_COLORS[args.value]?.bg || '#FFF'}
              color={LOG_COLORS[args.value]?.text || '#000'}
              mr={0}
            >
              {VERB_LABELS[args.value]}
            </Badge>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Target',
        accessor: 'target',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const filterChildren = (
    <>
      <ResponsiveBox>
        <Field
          name="actor"
          type="text"
          placeholder="Causer"
          groupProps={{ mb: 0 }}
        />
      </ResponsiveBox>
      <ResponsiveBox>
        <Field
          name="target"
          type="text"
          placeholder="Target"
          groupProps={{ mb: 0 }}
        />
      </ResponsiveBox>
      <ResponsiveBox>
        <Field
          minWidth="400px"
          name="verb"
          type="select"
          placeholder="Action"
          options={LOG_NAME_OPTIONS}
          groupProps={{
            mb: 0,
            textAlign: 'left',
          }}
        />
      </ResponsiveBox>
    </>
  );

  const { setFilters, instance, loading } = useTable({
    columns,
    fetchData,
  });
  return (
    <Page
      title="Activity Logs"
      action="list"
      subject="Log"
      browserTitle="Activity Logs"
      rightPanel={
        !isDesktop && (
          <DrawerFilter
            onSearch={setFilters}
            filterTitle="Filter By"
            initialValues={initialFilters}
            loading={loading}
          >
            {filterChildren}
          </DrawerFilter>
        )
      }
    >
      <Card>
        {isDesktop && (
          <Filter
            filterTitle="Filter By"
            onSearch={setFilters}
            initialValues={initialFilters}
            loading={loading}
          >
            {filterChildren}
          </Filter>
        )}
        <Table dense hovered instance={instance} />
      </Card>
    </Page>
  );
};

export default ActivityLog;
