import React from 'react';
import Button from '@bit/medicalwebexperts.mwe-ui.button';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import { shape, func, node, string, bool } from 'prop-types';

import Formik from '../Form/Formik';

const propTypes = {
  filterTitle: string.isRequired,
  onSearch: func.isRequired,
  initialValues: shape({}).isRequired,
  children: node.isRequired,
  loading: bool,
};

const defaultProps = {
  loading: false,
};

const Filters = ({
  filterTitle,
  onSearch,
  initialValues,
  children,
  loading,
}) => {
  const handleSearch = async (rawValues, formikBag) => {
    const values = Object.fromEntries(
      Object.entries(rawValues).filter(([, v]) => {
        if (Array.isArray(v)) {
          return v.length > 0;
        }
        return typeof v === 'object' ? v.value !== '' : v !== '';
      }),
    );
    await onSearch(values, formikBag);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSearch}
      onReset={handleSearch}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <>
          <Flex
            size="100%"
            alignItems="baseline"
            justifyContent="flex-start"
            p={3}
            flex={1}
            borderBottom="1px solid #eee"
          >
            <Typography fontSize="sm" flex="none" mr={2}>
              {filterTitle}
            </Typography>
            {children}
            <Box alignItems="center">
              {dirty && (
                <Button
                  p="0"
                  textAlign="center"
                  justifyContent="center"
                  type="reset"
                  variant="text"
                  disabled={loading}
                >
                  <Icon color="primary.main" name="close" size={5} />
                </Button>
              )}

              <Button
                maxWidth={200}
                inline
                size="sm"
                type="submit"
                variant="ghost"
                variantColor="primary"
                loading={isSubmitting}
                disabled={!isValid || !dirty || loading}
              >
                Search
              </Button>
            </Box>
          </Flex>
        </>
      )}
    </Formik>
  );
};

Filters.propTypes = propTypes;
Filters.defaultProps = defaultProps;
export default Filters;
