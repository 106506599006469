import React, { useState, useContext, createContext } from 'react';
import { node } from 'prop-types';
import { AuthResource } from '../services';
import client from '../services/client';

export const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  isLoggedIn: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

const propTypes = {
  children: node.isRequired,
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const refresh = client.$getRefreshToken();

  const isLoggedIn = () => {
    return user !== null && refresh;
  };

  const logout = async () => {
    client.$clearToken();
    setUser({});
  };

  const login = async (credentials) => {
    await AuthResource.login(credentials);
    const userData = await AuthResource.me();
    setUser(userData);
    return userData;
  };

  const reloadUser = async () => {
    const userData = await AuthResource.me();
    setUser(userData);
    return userData;
  };

  const context = {
    user,
    reloadUser,
    login,
    logout,
    isLoggedIn,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = propTypes;

export default AuthProvider;
