import React from 'react';
import { shape } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import { NavLink, useHistory, Link as RouterLink } from 'react-router-dom';
import { MdEvent, MdPerson, MdExitToApp } from 'react-icons/md';
import BaseSidebar from '@bit/medicalwebexperts.mwe-ui.sidebar';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import Link from '@bit/medicalwebexperts.mwe-ui.link';
import logo from '../../../assets/images/logo-mgfa.png';
import { useAuth } from '../../../contexts/AuthContext';
import useIdleLogout from '../../../hooks/useIdleLogout';
import IdleLogoutModal from '../../IdleLogoutModal';
import Config from '../../../config/index';

const propTypes = {
  state: shape({}).isRequired,
};

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const Logo = styled.div(
  css({
    img: {
      maxWidth: '100%',
    },
    my: 4,
    px: 4,
  }),
);

const Profile = styled.div(
  css({
    alignItems: 'center',
    bg: 'blackAlpha.50',
    display: 'flex',
    mx: 4,
    mt: 4,
    mb: 10,
    p: 4,
    borderRadius: 'md',
  }),
);

const ListItem = styled(List.Item)(
  css({
    '&:hover': {
      bg: 'blackAlpha.50',
    },
    '&.ListItem--active': {
      bg: 'blackAlpha.100',
      borderRightColor: 'primary.main',
    },
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'text',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'medium',
    p: 4,
  }),
);

const sidebarItems = [
  {
    label: 'Users',
    to: '/',
    icon: <MdPerson size={22} />,
  },
  {
    label: 'Activity',
    to: '/activity',
    icon: <MdEvent size={22} />,
  },
];

const Sidebar = (props) => {
  const { user, logout } = useAuth();
  const { replace } = useHistory();
  const snackbar = useSnackbarDispatch();

  const handleLogout = async () => {
    try {
      await logout();
      replace('/account/login');
    } catch {
      snackbar.open({
        message: 'Error on logged in out',
        status: 'error',
      });
    }
  };

  const [remainingSecs, isTimingOut] = useIdleLogout({
    countdowntimer: Config.portal.countdowntimer,
    idleTimer: Config.portal.iddleTime,
    handleLogout,
  });

  const { state } = props;
  return (
    <BaseSidebar {...props} classes={{ root: { border: 'none' } }}>
      <Wrapper>
        <Logo>
          <img src={logo} alt="MGFA" />
        </Logo>
        <Profile>
          <Avatar
            name={`${user?.firstName || ''} ${user?.lastName || ''}`}
            size="xs"
          />
          <Box pl={4}>
            <Typography variant="body2" fontWeight="bold">
              {`${user?.firstName || ''} ${user?.lastName || ''}`}
            </Typography>
            <Link variant="caption" as={RouterLink} to="/profile/edit">
              Profile
            </Link>
          </Box>
        </Profile>
        <List as="nav" flexGrow="1">
          {sidebarItems.map((item) => (
            <ListItem
              key={item.to}
              as={NavLink}
              to={item.to}
              onClick={() => state.hide()}
              activeClassName="ListItem--active"
              exact
            >
              <List.Item.Icon color="primary.main">{item.icon}</List.Item.Icon>
              {item.label}
            </ListItem>
          ))}
        </List>
        <ListItem as="a" onClick={handleLogout}>
          <List.Item.Icon color="danger">
            <MdExitToApp size={22} />
          </List.Item.Icon>
          Logout
        </ListItem>
      </Wrapper>
      <IdleLogoutModal status={isTimingOut} remainingSecs={remainingSecs} />
    </BaseSidebar>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
