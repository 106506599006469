import css from '@styled-system/css';
import styled from '@emotion/styled';
import BaseCard from '@bit/medicalwebexperts.mwe-ui.card';
import Header from './Header';

const Card = styled(BaseCard)(
  css({
    overflow: 'visible',
    border: 'none',
  }),
);

Card.Header = Header;
Card.Body = BaseCard.Body;
Card.Footer = BaseCard.Footer;

export default Card;
